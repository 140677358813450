<template>
  <div class="setting_form deep-links clear">
    <div class="white_box">
      <div class="box_inner">
        <div class="box_head d-flex align-items-center">
          <div class="left">
            <h2>Earn 30% Recurring Commissions!</h2>
            <p>Here’s how our affiliate program works:</p>
          </div>
        </div>
        <div class="settings_component">
          <div class="container ">
            <template v-if="hasFirstPromoterAuthToken">
              <div class="p-4">
                <iframe
                  height="850px"
                  width="100%"
                  frameborder="0"
                  :src="firstPromoterIframeSrc"
                ></iframe>
              </div>
            </template>
            <template v-else>
              <div class="row justify-content-center">
                <div class="col-md-3 m-4 border rounded text-center p-4">
                  <img width="100%" src="/assets/img/firstpromoter/first_step.png"/>
                  <h4 class="font-weight-bold">1. Join</h4>
                  <p class="mt-2">Sign up for the Replug affiliate program.</p>
                </div>
                <div class="col-md-3 m-4 border rounded text-center p-4">
                  <img width="100%" src="/assets/img/firstpromoter/second_step.png"/>
                  <h4 class="font-weight-bold">2. Refer</h4>
                  <p class="mt-2">Share your unique affiliate link with your friends and followers.</p>
                </div>
                <div class="col-md-3 m-4 border rounded text-center p-4">
                  <img width="100%" src="/assets/img/firstpromoter/third_step.png"/>
                  <h4 class="font-weight-bold">3. Earn</h4>
                  <p class="mt-2">Receive 30% commission on every sale we get from your affiliate link.</p>
                </div>
              </div>
              <div class="container w-100 py-4 px-5">
                <p class="text-center"><b>Note:</b> It’s not just that one payment when they sign up. That 30% commission
                  will be paid to you every month for as long as their Replug account is active.</p>
                <p class="text-center mt-2">Through our affiliate program you’re not only earning extra money for you and
                  your family, you’re also helping others streamline their social media and content marketing with
                  Replug.</p>
                <div class="d-flex justify-content-center align-items-center">
                  <button @click.prevent="becomeAffiliate"
                          class="btn---cta my-4 d-inline-flex btn-blue with-shadow btn-round btn-bold">
                    <span>Become Affiliate</span>
                    <clip-loader v-if="loader" class="icon ml-2" style="right: 30px;" :size="'12px'"
                                 :color="'white'"></clip-loader>
                  </button>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import http from '@/mixins/http-lib'
import { storeAffiliateFirstPromoter } from '@/config/api'

export default {
  data () {
    return {
      loader: false
    }
  },
  computed: {
    // Check if the user has First Promoter auth token
    hasFirstPromoterAuthToken() {
      return this.getProfile.first_promoter && this.getProfile.first_promoter.auth_token;
    },

    // Generate the iframe source URL with the auth token
    firstPromoterIframeSrc() {
      return this.hasFirstPromoterAuthToken
        ? `https://replug.firstpromoter.com//iframe?at=${this.getProfile.first_promoter.auth_token}`
        : '';
    },
  },
  methods: {
    /**
     * This method is responsible for creating the affiliate account
     * @returns {Promise<void>}
     */
    becomeAffiliate () {
      this.loader = true
      return http.post(storeAffiliateFirstPromoter).then(res => {
        if (res.data.status) {
          this.$store.commit('setProfile', res.data.profile)
        } else {
          this.alertMessage(res.data.message, 'error')
        }
        this.loader = false
        return res.data.data
      }).catch(err => {
        this.loader = false
        console.log(err)
      })
    }
  }
}
</script>
